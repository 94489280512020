/* This file contains all the constants for colors and font styles */
$body-font: "ETBook", Palatino, "Palatino Linotype", "Palatino LT STD", "Merriweather", "Chronicle Text G2", "Book Antiqua", Georgia, serif;
// Note that Gill Sans is the top of the stack and corresponds to what is used in Tufte's books
// However, it is not a free font, so if it is not present on the computer that is viewing the webpage
// The free Google 'Lato' font is used instead. It is similar.
$heading-font: "ETBook", "Merriweather", "Lato", Calibri, sans-serif;
$sans-font: "Gill Sans", "Gill Sans MT", "Gotham Narrow", "Lato", Calibri, sans-serif;
$code-font: Consolas, "Liberation Mono", Menlo, Courier, monospace;
$url-font: "Lucida Console", "Lucida Sans Typewriter", Monaco, "Bitstream Vera Sans Mono", monospace;
$text-color: #111;
$bg-color: #fffff8;
$contrast-color: #a00000;
// $text-color: #eee;
// $bg-color: #121212;
// $contrast-color: #df5c5c;
$border-color: #333333;
$link-style: color; // choices are 'color' or 'underline'. Color option styles links with $contrast-color set above



